import { ChevronDown, ChevronUp } from 'src/components/icons';

interface Props {
  showTopButton: boolean;
  showBottomButton: boolean;
  scrollToTop: () => void;
  scrollToBottom: () => void;
}

export default function ScrollButtons(props: Props) {
  return (
    <div className="hidden sm:block">
      {props.showTopButton && (
        <div
          onClick={props.scrollToTop}
          className="absolute top-0 right-0 cursor-pointer"
        >
          <div className="m-4 p-1 bg-greyscale-background hover:bg-greyscale-pink hover:text-primary rounded-full shadow-sm  shadow-black/10">
            <ChevronUp />
          </div>
        </div>
      )}
      {props.showBottomButton && (
        <div
          onClick={props.scrollToBottom}
          className="absolute bottom-0 right-0 rounded-b-lg cursor-pointer"
        >
          <div className="m-4 p-1 bg-greyscale-background hover:bg-greyscale-pink hover:text-primary rounded-full shadow-sm shadow-black/10">
            <ChevronDown className="translate-y-px" />
          </div>
        </div>
      )}
    </div>
  );
}
