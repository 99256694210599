import { useState } from 'react';
import Avatar from 'react-avatar';
import useSession from '../../stores/session/useSession';
import DrawerAccount from '../drawers/DrawerAccount';
import { Menu } from '../icons';

function ButtonAccount() {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSession();

  return (
    <>
      <button
        className={`
          flex items-center justify-center rounded-full h-10 w-10
          ${
            !!user
              ? 'bg-greyscale-placeholder text-greyscale-offwhite'
              : 'text-greyscale-body'
          }
        `}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {!!user ? (
          <Avatar
            size="40"
            round
            name={`${user.name} ${user.lastname}`}
            className="font-sans"
          />
        ) : (
          <Menu className="h-6 w-6" />
        )}
      </button>
      <DrawerAccount
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
      />
    </>
  );
}

export default ButtonAccount;
