import {IMGPW} from '@/config';
import {Link} from '@/navigation';
import Image from 'next/image';
import {Close} from 'src/components/icons';

interface Props {
  onCloseMenu: () => void;
}

export default function Header(props: Props) {
  return (
    <div className="sm:hidden bg-white border-b border-b-greyscale-separator px-2 flex justify-between">
      <Link href="/">
        <div className="p-4">
          <div className="h-10 relative w-32">
            <Image
              fill
              style={{objectFit: 'contain'}}
              src={IMGPW + '/winelivery.png'}
              alt="winelivery logo"
            />
          </div>
        </div>
      </Link>
      <div className="flex">
        <Close
          onClick={props.onCloseMenu}
          className="cursor-pointer self-center w-12 h-12 p-2 rounded-full hover:bg-greyscale-separator"
        />
      </div>
    </div>
  );
}
