import ToolbarLang from 'src/components/ToolbarLang';
import { Button } from 'src/components/design-system';
import { useTranslations } from 'next-intl';
import { MouseEvent } from 'react';
import LinkToLogin from '../../design-system/LinkToLogin';

interface Props {
  isAuth: boolean;
  openDrawer: () => void;
  handleLogout: (e: MouseEvent) => void;
}

export default function Footer(props: Props) {
  const t = useTranslations();
  return (
    <div className="space-y-4 p-6">
      {!props.isAuth ? (
        <LinkToLogin>
          <Button type="link" onClick={props.openDrawer} className="w-full">
            {t('common.drawer.login')}
          </Button>
        </LinkToLogin>
      ) : (
        <Button
          onClick={(e) => {
            props.openDrawer();
            props.handleLogout(e);
          }}
          theme="primary"
          className="w-full"
          palette="gray"
        >
          <span>Logout</span>
        </Button>
      )}
      <ToolbarLang />
    </div>
  );
}
