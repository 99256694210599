import { Link } from '../../../navigation';

interface Props {
  name: string;
  lastname: string;
  email: string;
}

export default function UserData(props: Props) {
  return (
    <Link href="/profile" className="no-underline min-w-0">
      <div>
        <h6 className="text-title text-xl font-bold mb-1 truncate">{`${props.name} ${props.lastname}`}</h6>
        <span className="text-sm text-greyscale-placeholder truncate block">
          {props.email}
        </span>
      </div>
    </Link>
  );
}
