import useOutsideClick from '../../hooks/useOutsideClick';
import { PropsWithChildren, useRef } from 'react';

interface Props extends PropsWithChildren {
  isOpen: boolean;
  onCloseMenu: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function FlyoutMenu(props: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(
    ref,
    () => {
      props.onCloseMenu();
    },
    [],
  );

  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="absolute pb-1 top-0 sm:top-full right-0 sm:my-8 w-screen sm:w-fit h-screen sm:h-fit overflow-hidden shadow sm:rounded-xl bg-gradient-to-r from-primary to-[#ef5989] z-50">
      <div
        ref={ref}
        className="h-full sm:h-auto sm:rounded-b-xl bg-greyscale-background flex flex-col sm:block"
      >
        {props.header}
        {props.children}
        {props.footer}
      </div>
    </div>
  );
}
