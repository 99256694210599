import { logout } from '@/actions/auth';
import useCartStore from '@/stores/cart/store';
import { useRouter } from 'next/navigation';
import { MouseEvent } from 'react';
import useSession from '../../../stores/session/useSession';
import FlyoutMenu from '../FlyoutMenu';
import Header from '../Header';
import Content from './Content';
import Footer from './Footer';

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

export default function DrawerAccount(props: IProps) {
  const { user, deleteSession } = useSession();
  const { emptyCart } = useCartStore((state) => state);
  const router = useRouter();

  const handleLogout = async (ev: MouseEvent) => {
    ev.preventDefault();
    emptyCart();
    const response = await logout();
    if (response.ok) {
      deleteSession();
      router.replace('/');
    }
  };

  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="relative sm:block contents">
      <FlyoutMenu
        isOpen={props.isOpen}
        onCloseMenu={props.toggle}
        header={<Header onCloseMenu={props.toggle} />}
        footer={
          <Footer
            isAuth={!!user}
            handleLogout={handleLogout}
            openDrawer={props.toggle}
          />
        }
      >
        <Content closeMenu={props.toggle} />
      </FlyoutMenu>
    </div>
  );
}
